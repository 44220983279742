import { type FC, lazy, Suspense } from 'react';

import { type Props } from './SharedFollowSearchBanner';

const LazyComponent = lazy(
    () => import(/* webpackChunkName: "SharedFollowSearchBanner" */ './SharedFollowSearchBanner')
);

export const SharedFollowSearchBannerLazy: FC<Props> = props => (
    <Suspense fallback="">
        <LazyComponent {...props} />
    </Suspense>
);
